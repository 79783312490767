// eslint-disable-next-line no-unused-vars
import React from "react";
import { List } from "immutable";
import { DATE, DIALOG, RENDER } from "../../components/orders-core/MUITable";
import MultiLineCell from "../../components/data-list/MultiLineCell";
import _ from "lodash";
import FlexBox, { ALIGN_CENTER } from "../../components/ui-core/FlexBox";
import { CallMade } from "@material-ui/icons";
import OrderListServiceType from "../../components/orders-core/OrderListServiceType";
import AvatarWithName2 from "../../components/avatars/AvatarWithName2";

export const ID = {
  ind: 1,
  slug: "id",
  label: "id",
  keyName: "id",
  name: "Id",
  type: DIALOG,
};
export const BARCODE = {
  ind: 1,
  slug: "barcode",
  label: "barcode",
  keyName: "barcode",
  name: "Barcode",
  type: DIALOG,
  align: "center",
  isCustomer: true,
};

export const CUSTOMER = {
  ind: 1,
  slug: "customer",
  label: "sender_name",
  keyName: "customer.name",
  name: "Customer",
  align: "center",
  longString: true,
};

export const WAREHOUSE = {
  ind: 2,
  slug: "warehouse",
  label: "warehouse",
  keyName: "warehouse",
  name: "Warehouses",
  type: RENDER,
  render: row => (
    <MultiLineCell
      firstLine={
        <FlexBox align={ALIGN_CENTER}>
          <CallMade
            fontSize="small"
            style={{
              color: "#A8ADAF",
              fontSize: "1rem",
              transform: "rotate(90deg)",
            }}
          />
          <span style={{ color: "#A8ADAF" }}>
            {_.get(row, "from_warehouse.name", "-")}
          </span>
        </FlexBox>
      }
      secondLine={
        <FlexBox align={ALIGN_CENTER}>
          <CallMade
            fontSize="small"
            style={{
              color: "#21C004",
              transform: "rotate(135deg)",
              fontSize: "1rem",
            }}
          />
          <span style={{ color: "#263238" }}>
            {_.get(row, "warehouse.name", "-")}
          </span>
        </FlexBox>
      }
      thirdLine={
        <FlexBox align={ALIGN_CENTER}>
          <CallMade
            fontSize="small"
            style={{
              color: "#A8ADAF",
              fontSize: "1rem",
            }}
          />
          <span style={{ color: "#A8ADAF" }}>
            {_.get(row, "to_warehouse.name", "-")}
          </span>
        </FlexBox>
      }
    />
  ),
};

export const SUPPLIER = {
  ind: 3,
  slug: "supplier_name",
  label: "supplier_name",
  keyName: "supplier.name",
  name: "Supplier Name",
};
export const DROPOFF_ADDRESS = {
  ind: 4,
  slug: "dropoff_address",
  label: "dropoff_address",
  keyName: "locations.1.address",
  name: "DropOff Address",
  align: "center",
  longString: true,
};
export const SERVICE = {
  ind: 5,
  slug: "service",
  label: "shipment_type",
  keyName: "package.delivery_label",
  name: "Service",
  type: RENDER,
  render: (row, getLocalisationMessage) =>
    _.get(row, "notification.enabled") ? (
      <OrderListServiceType order={row} />
    ) : _.get(row, "head_order_id") ? (
      <AvatarWithName2
        name={getLocalisationMessage("notification")}
        isNotification={true}
      >
        <MultiLineCell
          firstLine={
            <strong style={{ textTransform: "capitalize" }}>
              {getLocalisationMessage("notification")}
            </strong>
          }
        />
      </AvatarWithName2>
    ) : (
      <AvatarWithName2
        name={_.get(row, "package.delivery_label", "-")}
        imagePath={_.get(row, "package.courier_type_icon")}
      >
        <MultiLineCell
          firstLine={
            <strong>{_.get(row, "package.delivery_label", "-")}</strong>
          }
          secondLine={_.get(row, "package.menu.name")}
        />
      </AvatarWithName2>
    ),
};
export const CREATED_DATE = {
  ind: 9,
  slug: "created_date",
  label: "created_date",
  keyName: "created_date",
  name: "Created Time",
  type: DATE,
  isSortable: false,
  withFilter: false,
  align: "center",
};
export const STATUS = {
  ind: 17,
  slug: "status",
  label: "status",
  keyName: "status",
  name: "Status",
  withTranslation: true,
  align: "center",
  withFilter: true,
  withColor: true,
};
export const COMPLETED_DATE = {
  ind: 26,
  slug: "completed_date",
  label: "completed_date",
  keyName: "completed",
  name: "Completed Date",
  type: DATE,
  align: "center",
};
export default List([
  BARCODE,
  SERVICE,
  DROPOFF_ADDRESS,
  CREATED_DATE,
  COMPLETED_DATE,
  STATUS,
]);
